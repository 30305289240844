import React from 'react';
import {Text, Card, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PickupInfoBox.st.css';
import {cleanAddress} from '../../../domain/utils/cleanAddress';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepId} from '../../../types/app.types';
import {AddressModel} from '../../../domain/models/Address.model';

export enum PickupInfoBoxDataHook {
  root = 'PickupInfoBox.root',
  addressTitle = 'PickupInfoBox.addressTitle',
  addressDetails = 'PickupInfoBox.addressDetails',
  deliveryTimeDetails = 'PickupInfoBox.deliveryTimeDetails',
  instructionsTitle = 'PickupInfoBox.instructionsTitle',
  instructionsDetails = 'PickupInfoBox.instructionsDetails',
}

export interface PickupInfoBoxProps {
  instructions?: string;
  deliveryTime?: string;
  address?: AddressModel;
  insideTimeSelector?: boolean;
}

const PickupInfoBoxInternal = ({insideTimeSelector, instructions, deliveryTime, address}: PickupInfoBoxProps) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const {
    stepsManagerStore: {activeStep},
  } = useControllerProps();
  const shouldShowDeliveryTime = deliveryTime && activeStep.stepId === StepId.deliveryMethod;

  // eslint-disable-next-line no-nested-ternary
  return experiments.enabled(SPECS.ShowTimeSlotsUIChanges) ? (
    instructions ? (
      <div className={insideTimeSelector ? classes.insideTimeSelector : classes.root}>
        <Card data-hook={PickupInfoBoxDataHook.root} className={classes.card}>
          <Card.Container>
            {shouldShowDeliveryTime && (
              <Text
                data-hook={PickupInfoBoxDataHook.deliveryTimeDetails}
                priority={TextPriority.secondary}
                className={classes.deliveryTime}>
                {deliveryTime}
              </Text>
            )}
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {instructions}
            </Text>
          </Card.Container>
        </Card>
      </div>
    ) : null
  ) : (
    <div className={classes.root}>
      <Card data-hook={PickupInfoBoxDataHook.root} className={classes.card}>
        {!!address && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.addressTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_address_header()}
            </Text>
            <Text data-hook={PickupInfoBoxDataHook.addressDetails} priority={TextPriority.secondary}>
              {cleanAddress(
                localeKeys.checkout.delivery_method.pickup_address({
                  addressLine: address?.addressLine,
                  city: address?.city,
                  country: address?.countryFullname,
                  subdivision: address?.subdivisionFullname,
                  zipCode: address?.postalCode,
                })
              )}
            </Text>
          </Card.Container>
        )}
        {!!deliveryTime && (
          <Card.Container>
            <Text data-hook={PickupInfoBoxDataHook.deliveryTimeDetails} priority={TextPriority.secondary}>
              {deliveryTime}
            </Text>
          </Card.Container>
        )}
        {!!instructions && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {instructions}
            </Text>
          </Card.Container>
        )}
      </Card>
    </div>
  );
};

export const PickupInfoBox = React.memo(PickupInfoBoxInternal);
